.footer{
    background-color: $secondary;
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    &-text{
        padding-left: 3rem;
        color: $light;
        h2{
            font-weight: 800 ;
        }
        h4{
            font-weight: 400;
        }
        
    }
    @media(max-width:991px){
        flex-direction: column;
    }
}

.query{
    max-width: 50rem;
    width: 25%;
    padding-top: 2rem;
    padding-right: 3rem;
    &-form{
        #name,#email{
            max-width: 27rem;
            border-radius: 1rem;
            background-color: $light;
            width: 100%;
            line-height: 4;
            border: none;
            // margin: 1.5rem 
        }
        #message{
            // max-width: 50rem;
            border-radius: 1rem;
            width: 100%;
            background-color: $light;
            border: none;
            line-height: 4;
        }
        button{
            width: 100%;
            line-height: 3;
            border-radius: 1rem;
            background: var(--Beige, #DACFB0);
            border: none;
            font-weight: 500;
        }
    }
    @media(max-width:991px){
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 56rem;
        padding-right: 0;
    }
}