.header{
    background-color: $secondary;
    display: flex;
    justify-content: space-evenly;
    &-img{
        max-width: 35rem;
        width:54%;
        &-div{
            display: flex;
            justify-content: center;
            padding: 1rem;
        }
        @media(max-width: 450px){
            width:  100%;
        }
    }

    &-anchor{
        display: flex;
        align-self: center;
        a{
            color: $light;
            text-decoration: none;
            padding: 0rem 4rem;
            font-family: Inter;
            font-size: 1rem;
        }

        @media(max-width: 850px){
            a{
                padding: 0 1.5rem;
                font-size: 14px;
            }
        }
        @media(max-width: 550px){
            a{
                padding: 0 1rem;
                font-size: 9px;
            }
        }
        
    }
}
