.faq{
    display: flex;
    justify-content: space-between;
    padding: 0 10rem;
    padding-bottom: 2rem;
    &-head{
        color: #1C648C;
        font-size: 1.2rem;
        font-weight: 600;
    }
    &-para{
        padding-top: 1.3rem;
        color: var(--Text-txt-secondary, rgba(60, 60, 67, 0.85));
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2; /* 2.24rem */
    }
    &-mainhead{
        padding: 3rem;
        padding-left: 10rem;
        font-size: 2rem;
        color: #062B40;
    }
    @media(max-width: 1200px){
        padding: 0 6rem;
        padding-bottom: 2rem;
    }
    @media(max-width:767.67px){
        padding: 0 4rem;
        flex-direction: column-reverse;
        &-mainhead{
            padding: 0 1.5rem;
            text-align: center;
            font-size: 1.5rem;
        }
        &-head{
            font-size: 1rem;
        }
    }
}
.button-div{
    // padding-left: 8rem;
    button{
        border: none;
        padding-top: 10px;
        background-color: white;
    }
}
.dentist{
    &-img{
        width: 93%;
    }
    &-div{
        @media(max-width:767.67px){
            display: flex;
            justify-content: center;
            margin-bottom: 3rem;
        } 
    }     
}