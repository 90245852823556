.form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 5rem;
    margin-bottom: 2rem;
    &-img{
        display: flex;
        justify-content: center;
        padding-bottom: 3rem;;
        img{
            width: 30%;
        }
        @media(max-width:767.67px){
            img{
                width: 70%;
            }
        }
    }
    &-div{
        display: flex;
        justify-content: center;
        
        &-inner{
            border-radius: 1.5rem;
            background: rgb(218, 207, 177,0.3);
            padding: 2rem;
            width: 70%;
            h2{
                color: var(--Navy-Blue, #062B40);
                font-size: 1.5rem;
                font-weight: 800;
                margin: 0;
                padding: 1rem 0;
            }
        }

        label{
            color: var(--Navy-Blue, #062B40);
            font-size: 1rem;
            font-weight: 600;
            word-wrap: break-word;
            white-space: normal;
        }
        input{
            border: none;
            line-height: 3;
            border-radius: 0.6rem;
            margin: 1rem 0;;
        }

        &-label{
            &-1{
                display: flex;
                flex-direction: column;
            }
            &-2{
                display: flex;
                // justify-content: space-between;
            }
            &-3{
                padding-right: 1rem;
            }
            &-4{
                padding-left: 0.5rem;
            }
        }
        @media(max-width:1200px){
            &-stepslabel{
                text-align: center;
            }
        }
        @media(max-width:767.67px){
           
            &-inner{
                width: 80%;
            }
            label{
                font-size: 0.8rem;
            }
            &-label{
                &-2{
                    flex-direction: column;
                }
            }
        }
        
    }
    &-btn{
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        button{
            border-radius: 1.5rem;
            background: var(--Navy-Blue, #062B40);
            line-height: 3;
            font-size: 1.2rem;
            color: #FFF;
            font-weight: 500;
            padding: 0.2rem 7rem;
        }
    }
    &-card-parent{
        @media(max-width:1200px){
            flex-direction: column;
            align-items: center;
        }
    }
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 1.2rem;
    background: var(--Mid-Blue, #1C648C);
    max-width: 27.2rem;
    padding: 2rem;
    width: 30%;
    margin-right: 1.5rem;
    &-head{
        h2{
            font-size: 1.5rem;
            color : $light;
            font-weight: 700;
        }
    }
    &-text{
        span{
            color: var(--Beige, #DACFB0);
            font-size: 1rem;
        }
    }
    &-label{
        padding-top: 1rem;
        input{
            line-height: 4 !important;
        }
    }
    @media(max-width:1200px){
        margin-bottom: 1rem;
        width: 100%;
        margin-right: 0;
    }
}
.result-img{
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    img{
        padding-right: 3rem;
        // width: 30%;
        // max-width: 29rem;
    }
    button{
        border: none;
        background-color: white;
    }
}