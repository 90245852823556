.slick-slider {
    // max-width: 28rem;
    width:  100%; /* Full width of the parent container */
    height: auto; /* Height will be determined by the content */
  }
  
  .slick-slide {
    margin:  0  10px; /* Horizontal spacing between slides */
  }
  
  .slick-slide img {
    max-width: 28rem;
    width:  100%; /* Each image will take up the full width of the slide */
    height: auto; /* Aspect ratio will be maintained */
  }
  
.photoslider{

    &-text{
        text-align: center;
        padding-top: 2rem;
        h2{
            color: var(--Navy-Blue, #062B40);
            font-size: 2rem;
            font-weight: 700;
            text-transform: uppercase;
        }
        h4{
            color: var(--Mid-Blue, #1C648C);
            font-size: 1rem;
            font-weight: 700;
        }
    }
    &-div{
        padding:  3.5rem 22rem;
        padding-top: 2rem !important;
    }
    @media(max-width:1200px){
        &-text{
            padding-left: 1rem;
            padding-right: 1rem;
            h2{
                font-size: 1.5rem;
            }
            
        }
        &-div{
            padding: 3.5rem 6rem;
        } 
    }
    @media(max-width:800px){
        &-text{
            h2{
                font-size: 1rem;
            }
            h4{
                font-size: 13px;
            }
        }
        &-div{
            padding: 3rem 2rem;
        }
        
    }
}