.brush{
    &-img{
        position: relative;
        overflow: hidden;
        width:  100%; /* Set the width of the parent */
        height:  100%;
    }
    img{
        width:  100%; /* Make the image take up the full width of the parent */
        height: auto; /* Keep the aspect ratio of the image */
        object-fit: cover; /* Cover the entire area of the parent without stretching the image */
    }
    &-btn{
        position: absolute;
        left: 10%;
        top: 70%;
        button{
            border: none;
            background-color: $secondary;
            color: $light;
            font-size: 1rem;
            font-weight: 400;
            border-radius: 1.5rem;
            padding: 2rem 7.3rem;
        }
    }
    @media(max-width:992px){
        button{
            padding: 1.5rem 4rem;
        }
    }
}

.child{
    &-parent{
        display: flex;
        justify-content: center;
        position: relative;
        img{
            width: 80%;
        }

    }
    &-child{
        // background-color: black;
        position: absolute;
        bottom: 23%;
        left: 20%;
        h1{
            color: $light;
            font-size: 2.5rem;
            font-weight: 800;
            text-transform: uppercase;
        }
        button{
            border-radius: 1rem;
            background: var(--Navy-Blue, #062B40);
            align-items: center;
            padding: 1rem 5.3rem;
            font-size: 1.5rem;
            color: $light;
            border: none;
        }
        @media(max-width:1200px){
            left: 15%;
            h1{
                font-size: 1.5rem;
            }
            button{
                padding: 1rem 3rem;
                font-size: 1rem;
            }
        }
        @media(max-width:767.67px){
            bottom: 2%;
            padding: 0;
            left: 35%;
            font-size: 1.5rem;
            
            // top: 15%;
            // h1{
            //     font-size: 16px;
            // }
            // button{
            //     padding: 0.8rem;
            //     font-size: 10px;            
            // }
        }
        @media(max-width:500px){
            left: 26%;
            button{
                padding: 1rem 2rem;
            }
        }
    }
}