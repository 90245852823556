.service{
    padding: 2rem 2rem 2rem 10rem;
    h2{
        color: var(--Navy-Blue, #062B40);
        font-size: 2rem;
        font-weight: 700;
        // text-transform: uppercase;
        @media(max-width:767.67px){
            text-align: center;
        }
    }
    &-list{
        display: flex;
        padding-top: 1rem;
        // button{
        //     border: none;
        //     background-color: white;
        // }
        // &-img{
        //     border-radius: 50%;
        // }
        // &-img:hover{
        //     box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
        // }
        // &-first{
        //     padding-right: 3rem;
        // }
        // &-second{
        //     padding-left: 3rem;
        // }
        // &-text{
        //     color: var(--Mid-Blue, #1C648C);
        //     font-size: 2rem;
        //     font-style: normal;
        //     font-weight: 600;
        //     line-height: normal;
        // }
        
    }
    &-div1{
        @media(max-width:767.67px){
            display: flex;
            justify-content: center;
        }
    }
    &-div2{
        margin-left: 6rem;
        @media(max-width:767.67px){
            display: flex;
            justify-content: center;
            margin: 0;
        }
    }
    @media(max-width:767.67px){
        padding: 2rem 0;
        &-list{
            justify-content: space-evenly;
            img{
                width: 73%;
            }
        }
        h2{
            font-size: 1.5rem;
        }
    }
}