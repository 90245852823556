.vision{
    padding: 2rem 10rem;
    h2{
        color: var(--Navy-Blue, #062B40);
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
    }
    &-left{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 3rem;
        span{
            color: var(--Mid-Blue, #1C648C);
            font-size: 1.5rem;
            font-weight: 500;
            line-height: normal;
        }
        img{
            max-width: 4rem;
            width: 70%;
            padding: 0 1rem;
        }
        @media(max-width:1400px){
            span{
                font-size: 1rem;
            }
        }
    }
    &-right{
        img{
            // max-width: 4rem;
            // width: 70%;
            // padding: 0 1rem;
        }
    }
    &-img{
        display: flex;
        justify-content: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
        img{
            width: 79%;
        }
        @media(max-width:767.67px){
            padding-top: 2rem;
            padding-bottom: 0;
        }
    }
}