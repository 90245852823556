.modal {
    position: fixed;
    z-index:  1;
    left:  0;
    top:  0;
    width:  100%;
    height:  100%;
    overflow: auto;
    background-color: rgba(0,  0,  0,  0.4);
    &-content{
        background-color: #fefefe;
        margin:  2% auto;
        padding:  4rem;
        border:  1px solid #888;
        width:  80%;
    }
    &-header{
        h2{
            color : #062A40;
            font-size: 1.5rem;
        }
    }
    &-subhead{
        font-size: 1.2rem !important;
        font-weight: 500;
    }
    &-body{
        margin-top: 2rem;
        span{
            color : #062A40;
            font-size: 1rem;
            line-height: 1.5;
        }
        table{
            border-spacing: 0;
            border : 1px solid #B9B9B9;
            border-radius: 0.5rem;
        }
        
        th,td{
            max-width: 50rem;
            width: 40%;
            
            // border : 1px solid #B9B9B9;
        }
        thead{
            background-color: #062A40;
            color: #DACFB1;
            border-radius: 0.5rem;
        }
        
    }
    &-point{
        color: #277AA2;
        line-height: 1.5;
        // font-size: 0.8rem;
    }
}
  
.close-button {
    color: #aaa;
    float: right;
    font-size:  28px;
    font-weight: bold;
    &:hover,&:focus{
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

}

// .close-button:hover,
// .close-button:focus {
// color: black;
// text-decoration: none;
// cursor: pointer;
// }

.blue-box{
    text-align: center;
    border-radius: 1rem;
    background: #062A40;
    padding: 3rem;
    h2{
        color: #DACFB1;
        font-size: 2rem;
        font-weight: 700;
    }
    span{
        color: #EEE;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
    }
}
.table-head{
    border: 1px solid #B9B9B9;
}
.left{
    border-top : 1px solid #B9B9B9;
    border-right: 1px solid #B9B9B9;
    padding: 1rem;
    &-head{
        border-right: 1px solid #B9B9B9;
        padding: 1rem;
    }
}
.right{
    padding: 1rem;
    border-top : 1px solid #B9B9B9;
    &-head{
        padding: 1rem;
    }
}